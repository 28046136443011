<template>
	<div class="row mt-2 responsive-size">
		<div class="col-md-12">
			<h2 class="title-text" style="font-size: 28px!important;">ESPECIFICA EL TEMA A <b style="color:#8830df">PREDECIR</b></h2>
			<div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="0">
			<!-- Carousel indicators -->
			<ol class="carousel-indicators">
				<li data-target="#myCarousel" data-slide-to="0" class="active"></li>
				<li data-target="#myCarousel" data-slide-to="1"></li>
				<li data-target="#myCarousel" data-slide-to="2"></li>
        <li data-target="#myCarousel" data-slide-to="3"></li>
			</ol>   
			<!-- Wrapper for carousel items -->
			<div class="carousel-inner">
				<div class="item carousel-item active">
          <!-- PRIMEROS 4 ITEMS ACTIVOS -->
          <div class="tab-pane fade show active">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(12)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(0)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              

              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(11)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>


              <div class="col-12 col-md-6 col-lg-3" v-for="f in formatosPorId(1)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>
              


            </div>
          </div>
				</div>

				<div class="item carousel-item">
          <!-- SEGUNDOS 4 ITEMS ACTIVOS -->
          <div class="tab-pane fade show active">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorId(13)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorId(2)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>
              



              <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorId(6)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>


            </div>
          </div>
				</div>

        <div class="item carousel-item">
          <!-- TERCEROS 4 ITEMS ACTIVOS -->
          <div class="tab-pane fade show active">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-4 " v-for="f in formatosPorId(14)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                    <div class="promo-wrapper" @click="verForm(f.tipo)">
                        <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                        <p>{{ f.descripcion }}</p>
                    </div>
                </div>
              </div>
              
             
              <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorId(4)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>
            
              <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorId(3)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
				</div>

        <div class="item carousel-item">
          <!-- Cuarto 4 ITEMS ACTIVOS -->
          <div class="tab-pane fade show active">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorId(5)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>


              <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorId(7)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verForm(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorId(8)" :key="f.id">
                <div class="single-promo grad-hover text-center p-5">
                  <div class="promo-wrapper" @click="verCS(f.tipo)">
                    <h3 class="mb-3" style="font-size:2em!important">{{ f.nombre }}</h3>
                    <p>{{ f.descripcion }}</p>
                  </div>
                </div>
              </div>

             
            
            </div>
          </div>
				</div>


			</div>
			<!-- Carousel controls -->
			<a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
				<i class="fa fa-angle-left"></i>
			</a>
			<a class="carousel-control-next" href="#myCarousel" data-slide="next">
				<i class="fa fa-angle-right"></i>
			</a>
		</div>
		</div>


    <Modal :show="showModal" :modalHeadMessage="modalHeadMessage" :modalMessage="modalMessage" @close="showModal = false"></Modal>


	</div>

</template>

<script>
//import { mapState } from "vuex";
//import { auth, fr, analytics } from "../../firebase";
//import VueRecaptcha from "vue-recaptcha";
import Modal from '../Modal/ModalFormats/ModalBuild.vue';


export default {
  name: "FormatosMarket",
  metaInfo: {
    title: "Electoral Tech & legal | ChatET ",
    description:"Acesorias legales y consultoria de temas político-electorales",
  },
  components: {
    //"vue-recaptcha": VueRecaptcha,
    Modal
  },
  data() {
    return {
      showModal: false,
      modalMessage: '',
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal: "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
      captcha: false,
      formatos: [
    {
        id: 0,
        nombre: `Propaganda Prohibida\n\n(PP)`,
        descripcion: "",
        tipo: 1,
        grupo: 1,
    },
    {
        id: 1,
        nombre: "Fiscalización\n\n(FN)",
        descripcion: "",
        tipo: 2,
        grupo: 2,
    },
    {
        id: 2,
        nombre: "Acciones Afirmativas\n\n(AA)",
        descripcion: "",
        tipo: 3,
        grupo: 3,
    },
    {
        id: 3,
        nombre: "Actos Parlamentarios\n\n(AP)",
        descripcion: "",
        tipo: 4,
        grupo: 5,
    },
    {
        id: 4,
        nombre: "Tiempo de Radio y TV\n\n(TRTV)",
        descripcion: "",
        tipo: 5,  
        grupo: 4,
    },
    {
        id: 5,
        nombre: "Paridad de Género\n\n(PG)",
        descripcion: "",
        tipo: 6,  
        grupo: 5,
    },
    {
        id: 6,
        nombre: "Validez de elección\n\n(VE)",
        descripcion: "",
        tipo: 7,
        grupo: 7,
    },
    {
        id: 7,
        nombre: "Coacción al voto\n\n(CV)",
        descripcion: "",
        tipo: 8,
        grupo: 8,
    },
    {
        id: 8,
        nombre: "Actos vinculados con proceso electoral y voto en el extranjero\n\n(AVPEVE)",
        descripcion: "",
        tipo: 9,
        grupo: 8,
    },
    {
        id: 11,
        nombre: "Democracia Intrapartidista\n\n(DI)",
        descripcion: "",
        tipo: 10,
        grupo: 11,
    },
    {
        id: 12,
        nombre: "Violencia política en razón de género\n\n(VPG)",
        descripcion: "",
        tipo: 11,
        grupo: 12,
    },
    {
        id: 13,
        nombre: "Actos Anticipados de Campaña\n\n(AAC)",
        descripcion: "",
        tipo: 12,
        grupo: 6,
    },
    {
        id: 14,
        nombre: "Designación de Magistraturas e Integración de Órganos Electorales\n\n(DMIOE)",
        descripcion: "",
        tipo: 13,
        grupo: 9,
    },
]

    };
  },
  methods: {
    formatosPorId(id) {
      if (id == -1) {
        return this.formatos;
      }
      return this.formatos.filter(f => f.id === id);
    },
    // verForm(tipo) {
    //   let name = "";
    //   switch (tipo) {
    //     case 1:
    //       name = "PropagandaProhibida";
    //       break;
    //     case 2:
    //       name = "Fiscalizacion";
    //       break;
    //     case 3:
    //       name = "AccionesAfirmativas";
    //       break;
    //     case 4:
    //       name = "ActosParlamentarios";
    //       break;
    //     case 5:
    //       name = "TiempoRadioTV"
    //       break;
    //     case 6:
    //       name = "ParidadGenero"
    //       break;
    //     case 7:
    //       name = "ValidezEleccion"
    //       break;
    //     case 8:
    //       name = "CoaccionVoto"
    //       break;
    //     case 9:
    //       name = "ActosVinculadosProcesoElectoralVotoExtranjero"
    //       break;
    //     case 10:
    //       name = "DemocraciaIntrapartidista"
    //       break;
    //     case 11:
    //       name = "ViolenciaPoliticaGenero"
    //       break;
    //     case 12:
    //       name = "ActosAnticipadosCampaña"
    //       break;
    //     case 13:
    //       name = "DesignacionMagistraturasIntegracionOrganosElectorales"
    //       break;
        
    //   }
    //   this.$router.push({name});
    // },

    verForm(tipo) {
      let name = "";
      switch (tipo) {
        case 1:
          name = "PropagandaProhibida";
          break;
        case 2:
          name = "Fiscalizacion";
          break;
        case 3:
          name = "AccionesAfirmativas";
          break;
        case 4:
          name = "ActosParlamentarios";
          break;
        case 5:
          name = "TiempoRadioTV"
          break;
        case 6:
          name = "ParidadGenero"
          break;
        case 7:
          name = "ValidezEleccion"
          break;
        case 8:
          name = "CoaccionVoto"
          break;
        case 9:
          name = "ActosVinculadosProcesoElectoralVotoExtranjero"
          break;
        case 10:
          name = "DemocraciaIntrapartidista"
          break;
        case 11:
          name = "ViolenciaPoliticaGenero"
          break;
        case 12:
          name = "ActosAnticipadosCampaña"
          break;
        case 13:
          name = "DesignacionMagistraturasIntegracionOrganosElectorales"
          break;
        
      }
      this.$router.push({name});
    },

    verCS(tipo) {
      if (tipo !== 1 && tipo !== 11) {
        this.modalHeadMessage = 'En Construcción';
        this.modalMessage = 'Proximamente...';
        this.showModal = true;
      }
    },

  },

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');


.responsive-size {
    margin-left: 6rem;
    margin-right: 6rem;
  }

@media (max-width: 1200px) {
  .responsive-size {
    margin-left: 1px;
    margin-right: 1px;
  }
}

.title-text {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800!important;
  font-size: 20px!important;
}
h2 {
	color: #000;
	font-size: 10px;
	font-weight: 30;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin: 30px 0 20px;
}
h2::after {
	content: "";
	width: 100px;
	position: absolute;
	margin: 0 auto;
	height: 4px;
	border-radius: 1px;
	background: #8830df ;
	left: 0;
	right: 0;
	bottom: -20px;
}
.carousel {
	margin: 50px auto;
	padding: 0 70px;
  width:fit-content;  
  
}
.carousel .item {
	color: #747d89;
	min-height: 325px;
	text-align: center;
	overflow: hidden;
  
}
.carousel-control-prev, .carousel-control-next {
	height: 44px;
	width: 40px;
	background: #8830df;	
	margin: auto 0;
	border-radius: 4px;
	opacity: 0.8;
}
.carousel-control-prev:hover, .carousel-control-next:hover {
	background: #8830df;
	opacity: 1;
}
.carousel-control-prev i, .carousel-control-next i {
	font-size: 36px;
	position: absolute;
	top: 50%;
	display: inline-block;
	margin: -19px 0 0 0;
	z-index: 5;
	left: 0;
	right: 0;
	color: #fff;
	text-shadow: none;
	font-weight: bold;
}
.carousel-control-prev i {
	margin-left: -2px;
}
.carousel-control-next i {
	margin-right: -4px;
}		
.carousel-indicators {
	bottom: -50px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 4px;
	border-radius: 50%;
	border: none;
}
.carousel-indicators li {	
	background: rgba(0, 0, 0, 0.2);
}
.carousel-indicators li.active {	
	background: #2f0c71;
}

.promo-wrapper {
  cursor: pointer;
}

.single-promo h3 {
  white-space: pre-wrap;
}

.homepage-3 .single-promo h3 {
  font-size: 28px;
}
.active{
  background: none;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #e3d6ff;
  border-bottom: solid 5px #2f0c71;
  color: black;
}
.nav-link{
  font-size: 18px;
}
.encabezados{
  overflow: auto;
}
.grad-hover::before{
  background: #2f0c71;
}
.parallax-style{
  background-color: #f4f4f4;
  padding-top: 40px;
  padding-bottom: 150px;
}


</style>